import { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import "./App.css";
import { firebaseApp, db } from "./config/firebase";

function App() {
  const [machineState, setMachineState] = useState(null);
  const [partCount, setPartCount] = useState(null);

  useEffect(() => {
    const dbRefState = ref(db, "state");
    const unsubscribe = onValue(dbRefState, (snapshot) => {
      setMachineState(snapshot.val());
    });

    // Clean up the listener on component unmount
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const partCountRef = ref(db, "partCount");
    const unsubscribePartCount = onValue(partCountRef, (snapshot) => {
      setPartCount(snapshot.val());
    });

    return () => {
      unsubscribePartCount();
    };
  }, []);

  function getBackgroundColor(state) {
    switch (state) {
      case "CYCLE":
        return "green";
      case "ALARM":
        return "red";
      case "FEEDHOLD":
        return "orange";
      default:
        return ""; // Default background color or any other color you prefer
    }
  }

  return (
    <div className="App" style={{ backgroundColor: getBackgroundColor(machineState) }}>
      <header className="App-header" style={{ backgroundColor: getBackgroundColor(machineState) }}>
        <h1>counter: {partCount}</h1>
        <h1>{machineState}</h1>
      </header>
    </div>
  );
}

export default App;
